import React from "react"
import { graphql } from "gatsby"
// import Footer from "../components/footer"
import Layout from "../components/layout"
import Container from "../components/container"
import presets, { colors } from "../utils/presets"
import typography, { rhythm, scale } from "../utils/typography"
import { itemListStarter, itemListProd, itemListCase, itemListAbout, itemListDownload, itemListOnline, itemListFaq, itemListFree, itemListUser, itemListDl, itemListPd, itemListSolutions, itemListStarter3, itemListStart3 } from "../utils/sidebar/item-list"
import NewsWithPage from "./news-with-page"
import UpdatesWithPage from "./updates-with-page"
import SEO from "../components/seo";
import NoFound from "../pages/404";
import VideoPlay from "../components/common/videoPlay";

const getItemList = (pathname) => {
  let itemList = null
  if (pathname.slice(0, 12) === `/quick-start`) {
    itemList = itemListStarter
  } else if (pathname.indexOf('/product/') === 0) {
    itemList = itemListProd
  } else if (pathname.indexOf('/download/') === 0) {
    itemList = itemListDownload
  } else if (pathname.indexOf('/case/') === 0) {
    itemList = itemListCase
  } else if (pathname.indexOf('/about/') === 0) {
    itemList = itemListAbout
  } else if (pathname.indexOf('/online/') === 0) {
    itemList = itemListOnline
  } else if (pathname.indexOf('/FAQ/') === 0) {
    itemList = itemListFaq
  } else if (pathname.indexOf('/free/') === 0) {
    itemList = itemListFree
  } else if (pathname.indexOf('/user-case/') === 0) {
    itemList = itemListUser
  } else if (pathname.indexOf('/solutions/') === 0) {
    itemList = itemListSolutions
  } else if (pathname.indexOf('/quickstart/') === 0) {
    itemList = itemListStart3
  } 

  return itemList
}

export default ({ data, location }) => {
  const pathname = location.pathname
  const post = data.markdownRemark
  const itemList = getItemList(pathname)
  // console.log(location, itemList);
  if (!post) {
    return <NoFound location={location} />
  }
  let anchorMenu = post && post.frontmatter.anchorMenu
  if (anchorMenu && anchorMenu.length) {
    anchorMenu.map(item => {
      let menu = '';
      if (location.pathname.length - 1 === location.pathname.lastIndexOf('/')) {
        menu = location.pathname.substring(location.pathname.lastIndexOf('/', location.pathname.length - 2) + 1, location.pathname.lastIndexOf('/'))
      } else {
        menu = location.pathname.substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length)
      }
      item.key = itemList.length ? `${itemList[0].key}:${menu}` : '';
      item.link = location.pathname + '#' + item.id;
      item.hash = item.id
    })
  }

  let _container = null
  const paths = [{
    path: '/about/news/',
    type: 'about-news'
  }, {
    path: '/download/updateInstructions/',
    type: 'update-versions'
  }];
  const _pageWithList = paths.find(item => pathname.indexOf(item.path) === 0 && pathname.length > item.path.length)
  // console.log(_pageWithList);
  // pathname.indexOf('/about/news/') === 0 && pathname.length > 12
  if (_pageWithList) {
    if (_pageWithList.type === 'update-versions') {
      _container = <UpdatesWithPage id={post.id} listpath={_pageWithList} queryType={_pageWithList.type}><div dangerouslySetInnerHTML={{ __html: post.html }} /></UpdatesWithPage>
    } else {
      _container = <NewsWithPage id={post.id} listpath={_pageWithList} queryType={_pageWithList.type}><div dangerouslySetInnerHTML={{ __html: post.html }} /></NewsWithPage>
    }
  } else {
    _container = <div dangerouslySetInnerHTML={{ __html: post.html }} />
  }

  const _videos = post.frontmatter.videos
  return (
    <Layout location={location} itemList={itemList ? itemList : ``} contentTitle={post.frontmatter.title} anchorMenu={anchorMenu}>
      <SEO title={post.frontmatter.title} />
      {/* <div css={{ ...styles.contentHeader, ...styles.sticky }}>
          <h2 css={styles.contentTitle}>
              <span>
                  {post.frontmatter.title}
              </span>
          </h2>
      </div> */}
      <div css={{fontSize: "14px"}}>
        <Container overrideCSS={{padding: 24, minHeight: 'calc(100vh - 392px)', maxWidth: '100%', [presets.VVHd]: {
          minHeight: 'calc(100vh - 434px)'
        }}}>
        {/* <h3 css={{textAlign: `center`, paddingBottom: 15}}>{post.frontmatter.title}</h3> */}
          { _container }

          {_videos && _videos.length && _videos.map((item, i) => (
            <div key={i}>
              <VideoPlay videoProps={item} />
            </div>
          ))}
        </Container>
      </div>
      {/* <div css={styles.footer}>
        <Footer />
      </div> */}
    </Layout>
  )
}

// const styles = {
// 
// }

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        anchorMenu {
          id
          title
        }
        videos {
          videoTitle
          videoImg {
            publicURL
          }
          videoImg1 {
            publicURL
          }
          videoUrl {
            publicURL
          }
        }
      }
    }
  }
`
